import './header/header'
import './home-info/home-info'
import './inquiry/inquiry'
import './submit-project/submit-project'
import './email-form/email-form'
import './map/map'
import './list/list'
import './investment-map/investment-map'
import './startups/startups'
import './startup-modal/startup-modal'
