/* eslint-disable no-underscore-dangle */
import JustValidate from 'just-validate'
import generateDynamicCaptcha from '../../base/js/captcha.js'

import Modal from 'bootstrap/js/src/modal.js'
import getCookie from '../../base/js/getCookie.js'

const submitProjectModal = new Modal(document.getElementById('project-modal'))
const submitProjectModalNode = document.getElementById('project-modal')
const thanksModal = new Modal(document.getElementById('thanks-modal'))
const errorModal = new Modal(document.getElementById('error-modal'))

const inputs = submitProjectModalNode.querySelectorAll('.form-control__input')
const submitBtn = submitProjectModalNode.querySelector('.submit-project__btn button')

const modalCloseBtns = document.querySelectorAll('.modal-close')

document.querySelectorAll('.submit-project-trigger-modal').forEach(item => {
	item.addEventListener('click', () => {
		generateDynamicCaptcha()
		submitProjectModal.show()
	})
	return false
})

const siteLang = getCookie('pll_language') || document.querySelector('.language-dropdown-btn span').textContent
const formatSiteLang = siteLang.toLocaleLowerCase().slice(0, 2)
const langIsEn = formatSiteLang === 'en'

const formAction = submitProjectModalNode.querySelector('form').getAttribute('action')
const formMethod = submitProjectModalNode.querySelector('form').getAttribute('method')
//const grecaptcha = window.grecaptcha
const validator = new JustValidate('#submitProject')
const form = submitProjectModalNode.querySelector('form')

validator
	.addField('#project-name', [
		{
			rule: 'required',
			errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
		}
	])
	.addField('#project-address', [
		{
			rule: 'required',
			errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
		}
	])
	.addField('#project-initiator', [
		{
			rule: 'required',
			errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
		}
	])
	.addField('#project-opportunity', [
		{
			rule: 'required',
			errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
		}
	])
	.addField('#project-status', [
		{
			rule: 'required',
			errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
		}
	])
	.addField('#project-sector', [
		{
			rule: 'required',
			errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
		}
	])
	.addField('#project-contact', [
		{
			rule: 'required',
			errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
		}
	])
	.addField('#project-description', [
		{
			validator: value => {
				return value !== undefined && value.length > 3
			},
			errorMessage: langIsEn
				? 'Message should be more than 3 letters.'
				: 'Повідомлення повинно бути більше 3 букв.'
		}
	])

	.addField('#privacy', [
		{
			rule: 'required',
			errorMessage: langIsEn
				? 'Please accept our privacy policy'
				: 'Будь ласка, прийміть нашу політику конфіденційності'
		}
	])

/*const submitFormData = formData => {

	return 
}*/

const getFieldError = input => {
	setTimeout(() => {
		if (input.classList.contains('just-validate-error-field')) {
			input.closest('.form-control').classList.add('hasError')
		} else {
			input.closest('.form-control').classList.remove('hasError')
		}
	}, 100)
}

inputs.forEach(input => {
	input.addEventListener('input', () => {
		getFieldError(input)
	})
	input.addEventListener('onblur', () => {
		getFieldError(input)
	})
	input.addEventListener('onfocus ', () => {
		getFieldError(input)
	})
	input.addEventListener('onchange ', () => {
		getFieldError(input)
	})
})

modalCloseBtns.forEach(btn => {
	btn.addEventListener('click', () => {
		submitProjectModal.hide()
		thanksModal.hide()
		errorModal.hide()
	})
})

submitBtn.addEventListener('click', event => {
	//event.stopPropagation();
	event.preventDefault()

	inputs.forEach(input => {
		getFieldError(input)
	})

	validator.revalidate().then(isValid => {
		if (!isValid) return

		if (form.querySelector('.g-recaptcha-response').value == '') {
			grecaptcha.ready(function(){
				grecaptcha.execute(
					submitBtn.closest('form').querySelector('.sw_recaptcha').getAttribute('data-render')
				);
			});
			
			return false
		}

		const formData = new FormData(form, submitBtn)
		/*const payload = {};

		for (const [key, value] of formData) {
			payload[key] = value;
		}*/

		/*const input = form.querySelector('input[type="file"]')

		if (input.files.length != 0){ 
			formData.append('file', input.files[0])
		}*/

		

		fetch(formAction, {
			method: 'POST',
			//headers: {'Content-Type': 'multipart/form-data'},
			//cache: 'default',
			
			body: formData
		})
			.then(async response => {
				grecaptcha.ready(function(){
					grecaptcha.reset()
				});

				if (response.ok) {
					const json = await response.json()
					if (json.success === 1) {
						form.reset()
						form.querySelector('.form-control__file-remove').click()
						submitProjectModal.hide()
						thanksModal.show()
					} else {
						document.getElementById('form_error_text').innerHTML = json.error
						errorModal.show()
						inquiryModal.hide()
					}
				} else {
					errorModal.show()
					submitProjectModal.hide()
					document.getElementById('form_error_text').innerHTML = ''
					throw new Error(langIsEn ? 'Network response was not ok' : 'Відповідь мережі була неправильною')
				}

				//return response.json()
			})
			.catch(error => {
				errorModal.show()
				submitProjectModal.hide()
			})
		//submitFormData(formData)
	})
	return false
})

document.querySelectorAll('.form-control__label').forEach(label => {
	label.addEventListener('click', () => {
		if (!submitProjectModal._element.classList.contains('active')) {
			setTimeout(() => {
				submitProjectModal._element.classList.add('active')
			}, 300)
		}
	})
})

document.querySelectorAll('textarea').forEach(textarea => {
	textarea.addEventListener('input', () => {
		textarea.style.height = `${textarea.scrollHeight}px`
	})
})

submitProjectModalNode.addEventListener('hidden.bs.modal', () => {
	submitProjectModalNode.classList.remove('active')
	validator.refresh()
	inputs.forEach(input => {
		return input.closest('.form-control').classList.contains('hasError')
			? input.closest('.form-control').classList.remove('hasError')
			: null
	})
})
