// Vendor
import '../../vendor/vendor'

// Layouts
import '../../layouts/layouts'

// Components
import '../../components/components'

// Elements
import '../../elements/elements'

// Js usefull functions
import './counter'
import './setCookie'
import './getCookie'
import './captcha'

